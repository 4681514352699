import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {query} from "lit/decorators/query.js";
import {SbkCheckbox} from "@/components/checkbox/checkbox";


/**
 * An example element.
 *
 * @slot - This element has a slot
 */
@customElement('sbk-vpt-experts-upload-form')
export class SbkForm extends LitElement {

    @query('sbk-checkbox')
    _checkbox!: SbkCheckbox


    protected createRenderRoot() {
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        this._checkbox.addEventListener('checkbox-change', this._onChange);
    }

    _onChange() {
        const form: HTMLFormElement | null = document.querySelector('#filterOverview')
        if (form) {
            form.submit()
        }
    }
}
