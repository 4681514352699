import {customElement, query} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkButton} from "@/components/button/button";
import {SbkModal} from "@/components/modal/modal";

@customElement('sbk-vpt-sales-expert-upload')
export class SbkVptSalesExpertUpload extends LitElement {

    @query('#cancel')
    _cancelButton!: SbkButton

    protected createRenderRoot() {
        return this;
    }

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        this._cancelButton.addEventListener('click', this._closeModal)
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._cancelButton.removeEventListener('click', this._closeModal)
    }

    _closeModal(e: Event) {
        const modal: SbkModal | null = this.querySelector('sbk-modal')
        modal?._closeModal(e)
    }
}
